import React, { useState } from 'react';
import DynamicLinks from '../../../components/DynamicLink';
import { ArrowUpSvg, DropDownArrowSvg, OrangeTopSemiCircle, QuarterlyReturnsBottomSvg, ThreeDotsBulletSvg } from '../../../assets/svgs';
import ETDSComponent from './ETDS';

const QuarterlyReturns = () => {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const cardDataArray = [
        {
            linkData: [
                {
                    text: " FVU version 8.9 – Applicable for e-TDS/TCS Statements pertaining to Financial Year 2010-11 onwards.",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '187px',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/TDS_STANDALONE_FVU_8.9.zip",
                },
                {
                    text: "- FVU version 2.185– Applicable for e-TDS/TCS Statements pertaining to Financial Year 2007-08 to 2009-10.  ",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '187px',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/TDS_STANDALONE_FVU_2.185.zip",
                },
                {
                    text: "Instructions for extracting the files are given in:",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '187px',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/e-TDSFVUExtract.pdf",
                },
            ],
        },
    ];

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    return (
        <div className="md:px-[68px] py-4 md:py-10 text-[#0F0F0F]">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#EE7F25'} className={'w-[34px] h-[17px]'} />
                    File Validation Utility (FVU)
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <div className='bg-[#EE7F251A] relative p-8 gap-4'>
                <h1 className="text-base font-bold leading-[22px]">FVU for Quarterly Returns</h1>
                <p className="text-sm leading-[24px] font-normal">
                    e-TDS /TCS returns prepared for FY 2007-08 and onwards (i.e. Forms 24Q, 26Q, 27Q and 27EQ) can be validated using this utility.
                </p>
                <p className="text-sm leading-[24px] font-normal pt-4">
                    The e-TDS/TCS FVU is a Java based utility. JRE (Java Run-time Environment) [versions: SUN JRE: 1.6 onwards] should be installed on the computer where the e-TDS/TCS FVU is being installed. Java is freely downloadable from
                    <a href="http://java.sun.com" className="underline"> http://java.sun.com</a> and
                    <a href="http://www.ibm.com/developerworks/java/jdk" className="underline"> http://www.ibm.com/developerworks/java/jdk</a> or you can ask your vendor providing computer facilities (hardware) to install the same for you.
                </p>
                <p className="text-sm leading-[24px] font-normal pt-4">
                    The e-TDS/TCS FVU setup file (e-TDS/TCS FVU.exe) comprises of three files namely:
                </p>
                <ul className="list-disc list-inside text-sm leading-[24px] font-normal">
                    <li><span className='font-semibold'>TDS FVU Readme.rtf:</span> This file contains instructions for setup of the e-TDS FVU.</li>
                    <li><span className='font-semibold'>e-TDS FVU Setup.exe:</span> This is a setup program for installation of FVU.</li>
                    <li><span className='font-semibold'>These files are in an executable zip file.</span> These files are required for installing the e-TDS/TCS FVU.</li>
                </ul>
                <p className="text-[#E43625] text-sm leading-6 font-semibold pt-4">
                    “Please download and replace the existing folders with the latest version of FVU and RPU folders available at TIN website. Replacing only the FVU Jar file in the old folder may lead to the rejection of statement at the time of submission of the file.”
                </p>
                <div className="flex flex-col md:flex-row pt-4">
                    {cardDataArray.map((cardData, index) => (
                        <DynamicLinks key={index} linkData={cardData.linkData} className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[72px]"} titleColor='black' />
                    ))}
                </div>
                <OrangeTopSemiCircle className='absolute top-0 right-0' />
                <QuarterlyReturnsBottomSvg className='absolute hidden md:flex bottom-0 right-0' />
            </div>}
            {isOverviewOpen && <ETDSComponent />}
        </div>
    );
};

export default QuarterlyReturns;
